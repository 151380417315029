import React from 'react';
import BlogRoll from '../../components/BlogRoll';
import Layout from '../../components/Layout';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-jumbotron.jpg')`,
          }}
        >
          <h1 className="has-text-weight-bold is-size-1 page-title-image-heading">
            Latest Stories
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
